import React from "react"
import BasicLayout from "../layouts/basic-layout"
import { Row, Col } from "react-bootstrap"
import styled from "styled-components"
import { Map, Marker } from "react-amap"
import style from "../assets/global-style"

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`

const Title = styled.div`
  color: ${style["theme-color"]};

  p {
    margin: 0;
    padding: 0;
  }

  & > p:nth-child(1) {
    font-size: 32px;
    letter-spacing: 3px;
  }

  & > p:nth-child(2) {
    font-size: 19px;
    letter-spacing: 1px;
  }
`

const Card = styled.div`
  color: #333;
  & > div {
    font-size: 28px;
    letter-spacing: 3px;
    margin-bottom: 10px;
  }

  & > p {
    font-size: 17px;
  }
`

var pos = { longitude: 102.668904, latitude: 25.036125 }

const Page = () => (
  <BasicLayout
    width="100%"
    title="联系我们"
    bannerImg="http://maps.yncce.cn/website/contact-us.png"
  >
    <Container>
      <Row style={{ marginBottom: 20 }}>
        <Col md={12} sm={12} style={{ marginBottom: 40 }}>
          <Title>
            <p>联系我们</p>
            <p>CONTACT US</p>
          </Title>
        </Col>
        <Col md={6} sm={12}>
          <Card>
            <div>城建物业集团</div>
            <p>地址：云南省昆明市西二环路梁源小区城建大厦</p>
            <p>电话：4009999886</p>
            <p>邮编：650118</p>
          </Card>
        </Col>
        <Col md={6} sm={12}>
          <Card>
            <div>监察部</div>
            <p>地址：云南省昆明市西二环路梁源小区城建大厦</p>
            <p>电话：1895719990</p>
            <p>邮编：650118</p>
          </Card>
        </Col>
      </Row>
    </Container>
    <div style={{ height: 350 }}>
      <Map center={pos} zoom={17} amapkey={"622a18bfe57384249f2a864c6988d609"}>
        <Marker position={pos}></Marker>
      </Map>
    </div>
  </BasicLayout>
)

export default Page
